import { createStyles, Fab, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import KContainer from './components/KContainer';
import KSpacer from './components/KSpacer';
import i18next from './i18n';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            whiteSpace: 'pre-wrap',
            color: 'white'
        },
        header: {
            background: theme.palette.primary.main
        },
        textBox: {
            padding: 16
        },
        title: {
            paddingBottom: 8
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        buttonIcon: {
            marginRight: theme.spacing(1),
        },
        button: {
            textTransform: 'lowercase'
        }
    })
)

const Imprint: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()

    let sendEmail = () => {
        let link = `mailto:${t('imprint.email')}?subject=${t('imprint.emailSubject')}`
        window.open(link, '_blank')
    }

    return (
        <>
            <div className={classes.header}>
                <KContainer>
                    <KSpacer />
                    <Grid container>
                        <Grid item xs={12} md={6} className={classes.textBox}>
                            <Typography variant="h5" color="secondary" className={classes.title}>{t('imprint.feedbackTitle')}</Typography>
                            <Typography variant="body1" color="secondary">
                                {t('imprint.feedbackText')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.textBox}>
                            <Typography variant="h5" color="secondary" className={classes.title}>{t('imprint.developersTitle')}</Typography>
                            <Typography variant="body1" color="secondary">
                                {t('imprint.developersText')}
                            </Typography>
                        </Grid>
                    </Grid>

                    <KSpacer />
                    <div className={classes.buttonContainer}>
                        <Fab variant="extended" size="large" className={classes.button} onClick={sendEmail}>
                            <Email className={classes.buttonIcon} />
                            <Typography variant="body1"><b>{t('imprint.email')}</b></Typography>

                        </Fab>
                    </div>
                    <KSpacer />
                </KContainer>
            </div>

            <KSpacer />
            <KContainer>
                <div className={classes.textBox}>
                    <Typography variant="h5">{t('imprint.title')}</Typography>
                    <br />
                    <Typography variant="body1">{t('imprint.address')}</Typography>
                    {i18next.language === 'de' && (
                        <>
                            <br />
                            <Typography variant="body1"><b>{t('imprint.hrb1')}</b></Typography>
                            <Typography variant="body1">{t('imprint.hrb2')}</Typography>
                            <br />
                            <Typography variant="body1"><b>{t('imprint.responsible1')}</b></Typography>
                            <Typography variant="body1">{t('imprint.responsible2')}</Typography>
                        </>
                    )}
                </div>
            </KContainer>
            <KSpacer />
        </>
    )
}

export default Imprint
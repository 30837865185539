import * as React from "react";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";

const agb = `
# Angebotsbedingungen für das Bestellen von Kochbüchern

## § 1 Geltungsbereich – Vertragsgegenstand
(1) Unsere (develocity GmbH) AGB gelten für die Lieferung von beweglichen Sachen nach Maßgabe des zwischen uns und dem Kunden geschlossenen Vertrages.  
(2) Unsere AGB gelten ausschließlich; entgegenstehende oder von unseren AGB abweichende Bedingungen des Kunden erkennen wir nicht an, es sei denn, wir haben ausdrücklich schriftlich ihrer Geltung zugestimmt. Unsere AGB gelten auch dann, wenn wir in Kenntnis entgegenstehender oder von unseren Geschäftsbedingungen abweichender Bedingungen des Kunden die Lieferung vorbehaltlos ausführen.  
(3) Unsere AGB gelten sowohl gegenüber Verbrauchern als auch gegenüber Unternehmern, es sei denn, in der jeweiligen Klausel wird eine Differenzierung vorgenommen.

## § 2 Angebot – Vertragsschluss
(1) Die Bestellung des Kunden stellt ein bindendes Angebot dar, das wir innerhalb von zwei Tagen durch Zusendung einer (automatisierten) Auftragsbestätigung oder durch Lieferung der Ware annehmen können.  
(2) Die Darstellung von Produkten auf unserer Website oder in anderen Medien stellt kein verbindliches Angebot dar, sondern lediglich eine Aufforderung zur Abgabe eines Angebotes.  

## § 3 Preise und Zahlungsbedingungen
(1) In unseren Preisen ist die gesetzliche Umsatzsteuer enthalten.  
(2) Sofern nichts anderes vereinbart ist, sind Rechnungen sofort zur Zahlung fällig.  
(3) Sofern nichts anderes vereinbart ist, sind wir erst dann zur Leistung verpflichtet, wenn der Kunde seine Gegenleistung erbracht hat.  
(4) Aufrechnungsrechte stehen dem Kunden nur zu, wenn seine Gegenansprüche rechtskräftig festgestellt, unbestritten, mit unserer Hauptforderung im vertraglichen Austauschverhältnis verknüpft oder von uns anerkannt sind.  
(5) Der Kunde gibt bei Abgabe der Bestellung an, ob er per Visa oder Mastercard oder Lastschrifteinzug zahlen möchte.  
(6) Falls eine Abbuchung vom Konto des Kunden per Lastschrifteinzug aus vom Kunden zu vertretenden Gründen scheitert, hat der Kunde die durch die Rückbelastung entstehenden Bankbearbeitungsgebühren zu erstatten.  
(7) Wir behalten uns aus logistischen Gründen das Recht vor, den Rechnungsbeleg in elektronischer Form zu versenden. Der Rechnungsversand erfolgt in diesem Fall per E-Mail und ist kostenlos.

## § 4 Leistungszeit
(1) Sind von uns Lieferfristen angegeben und zur Grundlage der Auftragserteilung gemacht worden, verlängern sich solche Fristen bei Streik und in Fällen höherer Gewalt, und zwar für die Dauer der Verzögerung.  
(2) Falls kein fester Liefertermin vereinbart ist, erfolgt die Lieferung innerhalb von zwei Wochen nach Versand unserer Auftragsbestätigung.  
(3) Soweit die Geltendmachung von Rechten des Kunden die Setzung einer angemessenen Nachfrist voraussetzt, beträgt diese mindestens zwei Wochen.

## 5 Bestellungsmodalitäten – Liefermodalitäten - Liefergebiet
(1) Der Kunde hat selbst für die Sicherung seiner Daten zu sorgen. Eine Sicherung der uns vom Kunden übersandten Daten erfolgt nicht. Die vom Kunden übermittelten Bilddateien werden nach Ausführung des Auftrages gelöscht. Es obliegt dem Kunden selbst, seinen Auftrag abzuspeichern.  
(2) Wie liefern ausschließlich nach Deutschland.  
(3) Soweit eine Lieferung nicht möglich ist, weil der Kunde oder die von ihm als Empfänger bestimmte Person nicht unter der vom Kunden angegebenen Lieferadresse angetroffen wird, obwohl der Lieferzeitpunkt dem Kunden mit angemessener Frist angekündigt wurde, trägt der Kunde die Kosten für die erfolglose Anlieferung.  
(4) Teillieferungen sind zulässig, es sei denn, der Kunde hat erkennbar kein Interesse an ihnen oder sie dem Kunden erkennbar nicht zumutbar sind. Wenn wir von dem Recht zu Teillieferungen Gebrauch machen, werden Verpackungs- und Versandkosten nur einmalig erhoben.  

## § 6 Vertragliche Rücktrittsrechte
(1) Sollte der Kunde einen Ort für die Warenlieferung bestimmen, der außerhalb des in § 5 Absatz 2 genannten Liefergebietes liegt, so sind wir innerhalb von zwei Wochen nach der Bestellung dazu berechtigt, von dem Vertrag zurückzutreten.  
(2) Sollte die Übertragung von Bilddateien oder sonstigen Daten des Kunden, die zur Bearbeitung der Bestellung notwendig sind, fehlschlagen oder fehlerhaft sein, so sind wir innerhalb von zwei Wochen nach der Bestellung dazu berechtigt von dem Vertrag zurückzutreten.  
(3) Falls wir ohne eigenes Verschulden zur Lieferung bestellter Ware nicht in der Lage sind, weil uns unser Lieferant entgegen seiner vertraglichen Verpflichtung die für die Herstellung der Ware benötigten Rohmaterialien, technischen Einrichtungen oder Dienstleistungen nicht zur Verfügung stellt, sind wir gegenüber einem Kunden, der Verbraucher ist, bezüglich des nicht verfügbaren Teils der Bestellung zum Rücktritt berechtigt. In einem solchen Fall wird der Kunde unverzüglich darüber benachrichtigt, dass das bestellte Produkt nicht zur Verfügung steht. Weiterhin sind wir in diesem Fall verpflichtet, dem Kunden unverzüglich die bereits erhaltene Gegenleistung für die nicht verfügbare Ware zu erstatten. Die gesetzlichen Ansprüche des Kunden bleiben von dieser Lösungsklausel unberührt.  
(4) Gegenüber einem Kunden, der Unternehmer ist, sind wir zum Rücktritt berechtigt, wenn wir unsererseits nicht richtig oder rechtzeitig beliefert oder betreut werden, oder die Bestellung über unseren Vorrat oder unsere Produktionskapazität hinausgeht.  
(5) Bei Bestellungen, die eine haushaltsübliche Menge überschreiten, sind wir innerhalb von einer Woche nach Versand der Auftragsbestätigung dazu berechtigt, von dem Vertrag zurückzutreten.  
(6) Wir sind berechtigt, vom Vertrag zurückzutreten, wenn der Kunde über sein Vermögen einen Antrag auf Eröffnung des Insolvenzverfahrens gestellt, eine eidesstattliche Versicherung nach § 807 ZPO abgegeben hat oder das Insolvenzverfahren über sein Vermögen eröffnet oder die Eröffnung mangels Masse abgelehnt wurde.

## § 7 Widerrufsbelehrung – Widerrufsrecht
(1) Die nachfolgenden Absätze gelten nur für den Fall, dass der Kunde ein Verbraucher ist.  
(2) Es besteht kein Widerrufsrecht bei einem Vertrag zwischen uns und dem Kunden zur Lieferung von Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Kunden maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Kunden zugeschnitten sind. Da wir üblicherweise nur individualisierte Produkte anbieten, besteht üblicherweise für den Kunden kein Widerrufsrecht bezüglich des mit uns geschlossenen Vertrages!  
(3) Sollten wir ausnahmsweise Produkte anbieten, die nicht im Sinne des vorstehenden Absatzes individualisiert sind, hat der Kunde mit Blick auf den diesbezüglich abgeschlossenen Vertrag ein Widerrufsrecht. Für den Fall eines solchen Widerrufsrechts weisen wir den Kunden hin auf folgende Widerrufsbelehrung.

### Widerrufsrecht
Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die letzte Ware in Besitz genommen haben bzw. hat. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (develocity GmbH, Eichlbrunnstraße 28, 85416 Langenbach, E-Mail: contact@koerbchen.app) mittels einer eindeutigen Erklärung per E-Mail über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.

### Folgen des Widerrufs
Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.

Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns (develocity Gmbh, Eichlbrunnstraße 28, 85416 Langenbach) zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren. Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.

## § 8 Haftung für Mängel
(1) Sollte eine von uns erbrachte Leistung gegenüber dem Vertragspartner mangelhaft sein, so behalten wir uns die Wahl der Art der Nacherfüllung vor.  
(2) Die Gewährleistungsfrist für die von uns erbrachten Leistungen und Lieferungen beträgt ein Jahr. Die Verjährungsfrist im Falle eines Lieferregresses nach den §§ 478, 479 BGB bleibt unberührt.  
(3) Der Absatz 2 gilt nicht, soweit es sich um Schadensersatzansprüche wegen Mängeln handelt. Für Schadensersatzansprüche wegen eines Mangels gilt § 6.  
(4) Soweit nicht ausdrücklich etwas anderes vereinbart ist, erhält der Vertragspartner von uns keine Garantien im Rechtssinne.

## § 9 Haftung für Schäden
(1) Schadensersatzansprüche des Vertragspartners wegen eines Mangels verjähren nach einem Jahr. Dies gilt nicht, wenn wir vorsätzlich oder grob fahrlässig gehandelt haben oder bei der Verletzung von Leben, Körper oder Gesundheit des Vertragspartners.  
(2) Unsere Haftung für vertragliche Pflichtverletzungen sowie aus Delikt ist auf Vorsatz und grobe Fahrlässigkeit sowie auf Ersatz des typischerweise entstehenden Schadens beschränkt. Dies gilt nicht bei Verletzung von Leben, Körper, und Gesundheit des Vertragspartners, Ansprüchen wegen der Verletzung von Kardinalspflichten, d.h. von Pflichten, die sich aus der Natur des Vertrages ergeben und bei deren Verletzung die Erreichung des Vertragszieles gefährdet ist, sowie dem Ersatz von Verzugsschäden (§ 286 BGB). Insoweit haften wir für jeden Grad des Verschuldens. Soweit es um Schäden geht, die nicht aus der Verletzung von Leben, Körper und Gesundheit des Vertragspartners resultieren, haften wir aber nur für den typischerweise entstehenden Schaden. Die Haftung im Fall des Lieferverzugs ist für jede vollendete Woche des Verzugs im Rahmen einer pauschalierten Verzugsentschädigung auf 0,5 % des Lieferwertes, maximal jedoch nicht mehr als 5 % des Lieferwertes begrenzt.  
(3) Die vorgenannte Haftungsbeschränkung gilt ebenfalls für leicht fahrlässige Pflichtverletzungen unserer Erfüllungsgehilfen.  
(4) Soweit eine Haftung für Schäden, die nicht auf der Verletzung von Leben, Körper oder Gesundheit des Vertragspartners beruhen, für leichte Fahrlässigkeit nicht durch die vorstehenden Absätze ausgeschlossen ist, verjähren derartige Ansprüche innerhalb eines Jahres, beginnend mit der Entstehung des Anspruches.  
(5) Soweit die Schadensersatzhaftung uns gegenüber ausgeschlossen oder eingeschränkt ist, gilt dies auch im Hinblick auf die persönliche Schadensersatzhaftung unserer Angestellten, Arbeitnehmer, Mitarbeiter, Vertreter und Erfüllungsgehilfen.  
(6) Die Ansprüche aus dem Produkthaftungsgesetz bleiben unberührt.

## § 10 Eigentumsvorbehalt
(1) Bei Verträgen mit Verbrauchern behalten wir uns das Eigentum an dem Kaufgegenstand bis zur vollständigen Zahlung des Kaufpreises vor.  
(2) Ist der Kunde Unternehmer, behalten wir uns das Eigentum an der Ware bis zur Erfüllung sämtlicher Forderungen gegen den Kunden vor, auch wenn die konkrete Ware bereits bezahlt wurde.

## § 11 Form von Erklärungen
(1) Rechtserhebliche Erklärungen und Anzeigen, die der Kunde gegenüber uns oder einem Dritten abzugeben hat, bedürfen der Textform.  
(2) Die Vertragssprache ist Deutsch.

## § 12 Erfüllungsort – Rechtswahl – Gerichtsstand
(1) Soweit sich aus dem Vertrag nichts anderes ergibt, ist Erfüllungs- und Zahlungsort unser Geschäftssitz. Gegenüber Kunden die Verbraucher sind, bleiben die gesetzlichen Regelungen über Gefahrtragung sowie die gesetzlichen Gerichtsstände unberührt.  
(2) Für diesen Vertrag gilt das Recht der Bundesrepublik Deutschland. Die Anwendung des UN-Kaufrechts wird ausgeschlossen.  
(3) Ausschließlicher Gerichtsstand ist bei Verträgen mit Kaufleuten, juristischen Personen des öffentlichen Rechts oder öffentlich-rechtlichen Sondervermögen das für unseren Geschäftssitz zuständige Gericht.

## § 13 Datenschutz
Wir sind berechtigt, Daten, die zur Vertragserfüllung notwendig sind, an Dritte, insbesondere Vertragspartner weiterzugeben, soweit dies der Vertragsabwicklung dient.
Zur Bearbeitung der Aufträge werden nur die Personen- und Bilddaten übermittelt, die zur vollständigen Verarbeitung und Auslieferung nötig sind. Nachdem Ihr Auftrag fertig gestellt und ausgeliefert wurde, werden die Bilddaten bis zu drei Wochen in den Systemen gespeichert, um etwaige Reklamationen und Nachbestellungen bearbeiten zu können. Danach werden die Bilddaten datenschutzkonform gelöscht.

## § 14 Hinweis nach § 36 Verbraucherstreitbeilegungsgesetz (VSBG)
Wir sind nicht dazu bereit oder verpflichtet, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
`;

const PringAgb: React.FunctionComponent = (props) => {
  return (
    <div style={{ padding: 16 }}>
      <ReactMarkdown plugins={[remarkGfm]} children={agb}></ReactMarkdown>
    </div>
  );
};

export default PringAgb;

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 960,
            margin: '0 auto',
            width: '100%'
        }
    })
);

const KContainer: React.FC<any> = ({ ...params }) => {
    const classes = useStyles()
    return <div className={classes.root} {...params} />
}

export default KContainer;
import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import KContainer from './components/KContainer';
import KSpacer from './components/KSpacer';

const Terms: React.FC = () => {
    const { t } = useTranslation()

    return (
        <KContainer style={{ paddingLeft: 16, paddingRight: 16 }}>
            <KSpacer />
            <Typography variant="h5">{t('terms.title')}</Typography>
            <br />

            <Typography variant="h6" style={{ marginBottom: 8 }}>{t('terms.section0Title')}</Typography>
            <Typography>{t('terms.section0Text')}</Typography>

            <br />
            <Typography variant="h6" style={{ marginBottom: 8 }}>{t('terms.section1Title')}</Typography>
            <Typography>{t('terms.section1Text')}</Typography>

            <br />
            <Typography variant="h6" style={{ marginBottom: 8 }}>{t('terms.section2Title')}</Typography>
            <Typography>{t('terms.section2Text')}</Typography>

            <br />
            <Typography variant="h6" style={{ marginBottom: 8 }}>{t('terms.section3Title')}</Typography>
            <Typography>{t('terms.section3Text')}</Typography>

            <br />
            <Typography variant="h6" style={{ marginBottom: 8 }}>{t('terms.section4Title')}</Typography>
            <Typography>{t('terms.section4Text')}</Typography>

            <br />
            <Typography variant="h6" style={{ marginBottom: 8 }}>{t('terms.section5Title')}</Typography>
            <Typography>{t('terms.section5Text')}</Typography>

            <br />
            <Typography variant="h6" style={{ marginBottom: 8 }}>{t('terms.section6Title')}</Typography>
            <Typography>{t('terms.section6Text')}</Typography>

            <br />
            <Typography variant="h6" style={{ marginBottom: 8 }}>{t('terms.section7Title')}</Typography>
            <Typography>{t('terms.section7Text')}</Typography>

            <KSpacer />
            <Typography>{t('terms.date')}</Typography>
            <KSpacer />
        </KContainer>
    )
}

export default Terms
import { Typography } from '@material-ui/core'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import KContainer from './components/KContainer'
import KSpacer from './components/KSpacer'

const Privacy: React.FC = () => {
    const { t } = useTranslation()

    return (
        <KContainer style={{ paddingLeft: 16, paddingRight: 16 }}>
            <KSpacer />
            <Typography variant="h5">
                {t('privacy.title')}
            </Typography>
            <br />
            <Typography>
                {t('privacy.section0Text')}
            </Typography>

            <br />
            <Typography variant="h6">
                {t('privacy.section1Title')}
            </Typography>
            <br />
            <Typography>
                {t('privacy.section1Text', { email: t('imprint.email') })}
            </Typography>

            <br />
            <Typography variant="h6">{t('privacy.section2Title')}</Typography>
            <br />
            <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
                {t('privacy.section21Title')}
            </Typography>
            <Typography>
                <Trans i18nKey="privacy.section21Text">
                    <u>Firebase</u>
                    <a href="https://firebase.google.com/terms/data-processing-terms">https://firebase.google.com/terms/data-processing-terms</a>
                    <u>Facebook-Connect</u>
                    <a href="https://www.facebook.com/privacy/explanation und https://www.facebook.com/policies">https://www.facebook.com/privacy/explanation</a>
                    <a href="https://www.facebook.com/policies">https://www.facebook.com/policies</a>
                    <u>Google Sign-In</u>
                    <a href="https://policies.google.com/privacy?hl=de-de">https://policies.google.com/privacy?hl=de-de</a>
                </Trans>
            </Typography>
            <br />
            <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
                {t('privacy.section22Title')}
            </Typography>
            <Typography>
                <Trans i18nKey="privacy.section22Text">
                    <u>Google Admob</u>
                    <a href="https://policies.google.com/privacy?hl=de-de">Datenschutzerklärung von Google.</a>
                </Trans>
            </Typography>
            <br />
            <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
                {t('privacy.section23Title')}
            </Typography>
            <Typography>
                <Trans i18nKey="privacy.section23Text">
                    <u>Firbase Analytics</u>
                    <a href="https://firebase.google.com/policies/analytics">https://firebase.google.com/policies/analytics</a>
                    <u>Facebook Analytics</u>
                    <a href="https://www.facebook.com/privacy/explanation">https://www.facebook.com/privacy/explanation</a>
                </Trans>
            </Typography>
            <br />
            <Typography variant="h6" style={{ marginBottom: 8 }}>
                {t('privacy.section3Title')}
            </Typography>
            <Typography>
                {t('privacy.section3Text')}
            </Typography>
            <KSpacer />
        </KContainer>
    )
}

export default Privacy
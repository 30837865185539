import { CssBaseline, ThemeProvider } from "@material-ui/core";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import App from "./App";
import "./i18n";
import Import from "./Import/Import";
import Imprint from "./Imprint";
import PringAgb from "./PrintAgb";
import Privacy from "./Privacy";
import * as serviceWorker from "./serviceWorker";
import Terms from "./Terms";
import theme from "./theme";

const ScrollToTop: React.FC<any> = ({ ...params }) => {
  let location = useLocation();
  const pathname = location.pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return params.children;
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <Route path="/mobile/terms" component={Terms} />
            <Route path="/mobile/privacy" component={Privacy} />
            <Route path="/mobile/imprint" component={Imprint} />
            <Route path="/mobile/import" component={Import} />
            <Route path="/android/import" component={Import} />
            <Route path="/cookbooks/agb" component={PringAgb} />
            <Route component={App} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

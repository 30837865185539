import { createStyles, ListItem, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import KSpacer from '../components/KSpacer';
import i18next from '../i18n';
import sitesDe from './sitesDe';
import sitesEn from './sitesEn';


const web2 = require('../assets/2_web_de.png')

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 16
        },
        title: {
            paddingTop: 8
        }
    })
)

interface SiteItemProps {
    label: string
    url: string
}

const SiteItem: React.FC<SiteItemProps> = ({ label, url }) => {
    return (
        <ListItem button component="a" href={url} >
            <Typography><u>{label}</u></Typography>
        </ListItem >
    )
}

const Import: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const sites = i18next.language === 'de' ? sitesDe : sitesEn
    return (
        <div className={classes.container}>
            {sites.map((section) => (
                <>
                    <Typography variant="h6" className={classes.title}>{section.title}</Typography>
                    {section.sites.map(props => <SiteItem key={props.label} {...props} />)}
                </>
            )
            )}

            <KSpacer />
            <Typography variant="h6">{t('import.hintTitle')}</Typography>
            <Typography>{t('import.hintText')}</Typography>
            <img src={web2} alt="" style={{ height: 300, paddingTop: 16 }} />

        </div>
    )
}

export default Import;
import { createStyles, Tab, Theme, withStyles } from "@material-ui/core";
import React from 'react';

interface Props {
    selected?: boolean
    label: string
    value: string
}

const KTab: React.FC<Props> = ({ ...props }) => <Tab disableRipple {...props} />

export default withStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: 120,
            fontSize: theme.typography.h6.fontSize,
            fontWeight: theme.typography.h6.fontWeight,
            textTransform: 'none',
            [theme.breakpoints.down('sm')]: {
                minWidth: 80,
                fontSize: '1rem',
            },
        }
    }))(KTab);
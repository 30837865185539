import { AppBar, Button, createStyles, Hidden, makeStyles, Theme, Toolbar, Typography } from '@material-ui/core';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useScrollYPosition } from 'react-use-scroll-position';
import About from './About';
import Container from './components/KContainer';
import KTab from './components/KTab';
import KTabs from './components/KTabs';
import Home from './Home/Home';
import Imprint from './Imprint';
import Privacy from './Privacy';
import Terms from './Terms';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    content: {
      marginTop: 56,
      flex: 1,
      whiteSpace: 'pre-wrap'
    },
    toolbar: {
      alignItems: 'flex-end',
      minHeight: 56,
      backgroundColor: theme.palette.primary.main
    },

    icon: {
      width: 80,
      height: 80
    },


    naviButton: {
      minWidth: 120,
      minHeight: 56,
      "&:hover": {
        backgroundColor: 'transparent',
      },
      textTransform: 'none'
    },

    footerNote: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 12
    }
  })
);

const App: React.FC = () => {
  const scrollY = useScrollYPosition();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()



  useEffect(() => {
    const query = queryString.parse(location.search);

    if (query['open'] === 'app') {
      //forward deeplinks to webapp
      const appUrl = window.location.toString().replace(`${window.location.protocol}//${window.location.host}`, 'https://web.koerbchen.app');
      window.location.assign(appUrl);
    }

  });

  return (
    <div className={classes.root}>
      <AppBar elevation={scrollY > 20 ? 4 : 0}>
        <Toolbar className={classes.toolbar}>
          <Container>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }} />
              <KTabs value={location.pathname} onChange={(_, value) => history.push(value)}>
                <KTab label={t('navi.home')} value="/" />
                <KTab selected label={t('navi.about')} value="/about" />
                <KTab label={t('navi.contact')} value="/imprint" />
              </KTabs>
            </div>
          </Container>
        </Toolbar>
      </AppBar>
      <div className={classes.content} >
        <Switch>
          <Route path="/imprint" component={Imprint} />
          <Route path="/about" component={About} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/terms" component={Terms} />
          <Route component={Home} />
        </Switch>
      </div>

      <div className={classes.footerNote}>
        <Typography variant="subtitle1">{t('made')}</Typography>
      </div>

      <Toolbar className={classes.toolbar}>
        <Container>
          <Hidden xsDown><Button disableRipple className={classes.naviButton} onClick={() => history.push('/imprint')}><Typography variant="h6" color="secondary">{t('navi.developers')}</Typography></Button></Hidden>
          <Button disableRipple className={classes.naviButton} onClick={() => history.push('/terms')}><Typography variant="h6" color="secondary">{t('navi.terms')}</Typography></Button>
          <Button disableRipple className={classes.naviButton} onClick={() => history.push('/privacy')}><Typography variant="h6" color="secondary">{t('navi.privacy')}</Typography></Button>
        </Container>
      </Toolbar>
    </div>

  );
}

export default App;
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import common_de from './translations/de.json';
import common_en from './translations/en.json';

i18next
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        //whitelist: ['de', 'en'],
        //fallbackLng: 'de',
        debug: true,
        resources: {
            de: {
                translation: common_de
            },
            en: {
                translation: common_en
            }
        },

        interpolation: {
            escapeValue: true, // not needed for react as it escapes by default
        },
    });


export default i18next;
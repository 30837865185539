import { createStyles, makeStyles, Tabs, Theme } from "@material-ui/core";
import React from 'react';

interface Props {
    value: string;
    onChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        indicator: {
            height: 3,
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            '& > div': {
                maxWidth: 60,
                width: '100%',
                backgroundColor: '#fff',
            }
        }
    })
);

const KTabs: React.FC<Props> = ({ ...props }) => {
    const classes = useStyles()
    return <Tabs
        TabIndicatorProps={{ className: classes.indicator, children: <div /> }}
        {...props}
        textColor="secondary"
    />
}

export default KTabs;
import { Avatar, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import KContainer from './components/KContainer';
import KSpacer from './components/KSpacer';

const me = require('./assets/christian.jpg')
//const flutter = require('./assets/flutter-logo.png')
//const firebase = require('./assets/firebase-logo.png')

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column-reverse'
            }
        },
        container: {
            paddingLeft: 16,
            paddingRight: 16,
        },
        header: {
            paddingLeft: 16,
            paddingRight: 16,
            background: theme.palette.primary.main
        },
        title: {
            paddingBottom: 8
        },
        avatar: {
            width: 120,
            height: 120
        }
    })
)

const About: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <>
            <div className={classes.header}>
                <KContainer>

                    <Grid container className={classes.grid}>
                        <Grid item xs={12} sm={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                            <Avatar alt="" src={me} className={classes.avatar} />,
                            <Typography color="secondary">
                                <b>Christian Kirst</b>
                            </Typography>
                            <Typography color="secondary">
                                {t('about.myTitle')}
                            </Typography>
                            <KSpacer />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <KSpacer />
                            <Typography variant="h5" color="secondary" className={classes.title}>{t('about.title')}</Typography>
                            <Typography color="secondary">{t('about.text')}</Typography>
                            <KSpacer />
                        </Grid>
                    </Grid>
                </KContainer>
            </div>

            <KContainer >
                <KSpacer />
                <div className={classes.container}>
                    <Typography variant="h5" className={classes.title}>{t('about.supportTitle')}</Typography>
                    <Typography>{t('about.supportText')}</Typography>
                </div>
                <KSpacer />
            </KContainer>


        </>
    )
}

export default About

/*
<KContainer >
                <KSpacer />
                <div className={classes.container}>
                    <Typography variant="h5" className={classes.title}>Unterstütze körbchen</Typography>
                    <Typography>
                        In körbchen stecken viele hundert Stunden Arbeit. Ständige Verbesserungen machen die App zu dem, was sie heute ist. Auch du kannst mithelfen, die App noch besser zu machen: Gib uns Feedback, kaufe die Premiumversion und hilf mit die App zu verbreiten.
                        <br />
                        <br />
                     Unsere Vision ist es, die App in Zukunft DER Plattform fürs Sammeln und Verwalten der eigenen Kochrezepte zu machen. Die nächsten großen Schritte sind der Release der Web-App und das Einscannen von Rezepten.
                    </Typography>
                </div>
                <KSpacer />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 8 }}>
                    <Typography variant="h5" className={classes.title}>Technologien</Typography>
                    <img alt="flutter" src={flutter} style={{ width: 250 }} />
                    <img alt="firebase" src={firebase} style={{ width: 300 }} />
                </div>
            </KContainer>
            */
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import React from 'react';

interface Props {
    name: string
    text: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
        },

        icon: {
            width: 80,
            height: 80
        }
    })
);

const Testimonial: React.FC<Props> = ({ name, text }) => {
    const classes = useStyles()
    return (
        <div className={classes.container}>
            <AccountCircle color="disabled" className={classes.icon} />
            <Typography variant="subtitle1">{name}</Typography>
            <Typography variant="body2">„{text}“</Typography>
        </div>
    )
}

export default Testimonial
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import KContainer from '../components/KContainer';
import i18next from '../i18n';
import AppButtons from './AppButtons';
import Feature from './Feature';
import Testimonial from './Testimonial';

const web1De = require('../assets/1_web_de.png')
const web1En = require('../assets/1_web_en.png')
const web2De = require('../assets/2_web_de.png')
const web2En = require('../assets/2_web_en.png')
const web3De = require('../assets/3_web_de.png')
const web3En = require('../assets/3_web_en.png')
const web4De = require('../assets/4_web_de.png')
const web4En = require('../assets/4_web_en.png')
const web5De = require('../assets/5_web_de.png')
const web5En = require('../assets/5_web_en.png')
const appStore = require('../assets/app_store.png')
const playStore = require('../assets/play_store.png')
const techfounders = require('../assets/techfounders.png')
const chrome = require('../assets/chrome.png')

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        primary: {
            background: theme.palette.primary.main
        },
        triangle: {
            background: theme.palette.primary.main,
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 70%);',
            [theme.breakpoints.down('sm')]: {
                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 80%);',
            }
        },
        headerGrid: {
            minHeight: 600
        },
        webapp: {
            marginTop: 8,
            width: 312,
            [theme.breakpoints.down('xs')]: {
                width: 152
            }
        },
        contentBox: {
            marginLeft: 32,
            marginRight: 32,
        },
        textBox: {
            padding: 32,
            [theme.breakpoints.down('sm')]: {
                padding: 0,
                paddingTop: 32
            },
            whiteSpace: 'pre-wrap'
        },
        title: {
            textTransform: 'uppercase'
        },
        subtitle: {
            paddingTop: 16
        },
        imageBox: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 40
        },
        image: {
            maxWidth: 400,
            width: '100%',
            height: '100%',
            objectFit: 'contain',
        },
        testimonials: {
            paddingTop: 48,
            textAlign: 'center'
        },
        powered: {
            paddingTop: 48,
            paddingBottom: 48,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    })
);



const Home: React.FC = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const web1 = i18next.language === 'de' ? web1De : web1En
    const web2 = i18next.language === 'de' ? web2De : web2En
    const web3 = i18next.language === 'de' ? web3De : web3En
    const web4 = i18next.language === 'de' ? web4De : web4En
    const web5 = i18next.language === 'de' ? web5De : web5En

    let openIos = () => {
        window.open(t('storeLinkIos'), '_blank')
    }

    let openPlayStore = () => {
        window.open(t('storeLinkAndroid'), '_blank')
    }

    let openWebApp = () => {
        window.open("https://web.koerbchen.app", '_blank')
    }

    return (
        <div>
            <div className={classes.triangle}>
                <KContainer>
                    <div className={classes.contentBox}>
                        <Grid container className={classes.headerGrid}>
                            <Grid item xs={12} sm={7} md={6}>
                                <div className={classes.textBox}>
                                    <Typography variant="h4" color="textSecondary" className={classes.title}>
                                        {t('home.headerTitle')}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" className={classes.subtitle}>
                                        {t('home.headerSubtitle')}
                                    </Typography>
                                    <AppButtons />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5} md={6} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                                <div className={classes.imageBox}>
                                    <img alt="" src={web1} className={classes.image} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </KContainer>
            </div>

            <KContainer>
                <Feature image={web2} title={t('home.feature1Title')} text={t('home.feature1Text')} />
                <Feature image={web3} title={t('home.feature2Title')} text={t('home.feature2Text')} invers />
                <Feature image={web4} title={t('home.feature3Title')} text={t('home.feature3Text')} />
                <Feature image={web5} title={t('home.feature4Title')} text={t('home.feature4Text')} invers />
            </KContainer>

            <KContainer>
                <Grid container className={classes.testimonials}>

                    <Grid item xs={12} sm={3} md={3}>
                        <Testimonial name={t('home.testimonial1Name')} text={t('home.testimonial1Text')} />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Testimonial name={t('home.testimonial2Name')} text={t('home.testimonial2Text')} />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Testimonial name={t('home.testimonial3Name')} text={t('home.testimonial3Text')} />
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Testimonial name={t('home.testimonial4Name')} text={t('home.testimonial4Text')} />
                    </Grid>
                </Grid>
            </KContainer>

            <div className={classes.powered}>
                <Typography variant="subtitle1" style={{ marginBottom: -10 }}>powered by</Typography>
                <img src={techfounders} alt="" style={{ width: 300 }} />
            </div>





        </div>


    )
}

export default Home;
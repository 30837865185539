
import { Button, createStyles, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const appStore = require('../assets/app_store.png')
const playStore = require('../assets/play_store.png')
const chrome = require('../assets/chrome.png')

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        webapp: {
            marginTop: 8,
            width: 312,
        }
    })
);

const useInverseStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            flexDirection: 'row-reverse',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse'
            },
        }
    })
);

const AppButtons: React.FC<{}> = () => {
    const { t } = useTranslation()
    const classes = useStyles()
    const matches = useMediaQuery('(min-width:312px)');

    let openIos = () => {
        window.open(t('storeLinkIos'), '_blank')
    }

    let openPlayStore = () => {
        window.open(t('storeLinkAndroid'), '_blank')
    }

    let openWebApp = () => {
        window.open("https://web.koerbchen.app", '_blank')
    }

    console.log(matches)

    return (
        <>
            <div style={{ paddingTop: 16, display: 'flex', flexWrap: 'wrap' }}>
                <Button style={{ padding: 0 }} onClick={openIos}>
                    <img src={appStore} alt="" style={{ height: 50 }} />
                </Button>
                <div style={{ width: 8 }} />
                <Button style={{ padding: 0 }} onClick={openPlayStore}>
                    <img src={playStore} alt="" style={{ height: 50 }} />
                </Button>
            </div>
            <Button
                style={{ width: matches ? 312 : 152 }}
                className={classes.webapp}
                startIcon={<img src={chrome} alt="" style={{ height: 24 }} />}
                variant="contained"
                color="secondary"
                disableElevation
                fullWidth
                onClick={openWebApp}
            >
                <b>Web-App</b>

            </Button>
        </>
    )
}

export default AppButtons
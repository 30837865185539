import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';

interface Props {
    image: string
    title: string
    text: string
    invers?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse'
            },
            paddingBottom: 32
        },
        imageContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse',
                justifyContent: 'center',
            },
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: 32,
            whiteSpace: 'pre-wrap'
        },
        title: {
            paddingBottom: 8
        }
    })
);

const useInverseStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            flexDirection: 'row-reverse',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse'
            },
        },
        imageContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: 32,
            whiteSpace: 'pre-wrap',
            textAlign: 'end',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'start',
            }
        },
        title: {
            paddingBottom: 8
        }
    })
);

const Feature: React.FC<Props> = ({ image, title, text, invers }) => {
    const styles = useStyles()
    const inversStyles = useInverseStyles()
    let classes = invers ? inversStyles : styles
    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} md={6} className={classes.imageContainer}>
                <img src={image} alt="" style={{ height: 300 }} />
            </Grid>
            <Grid item xs={12} md={6} className={classes.textContainer}>
                <Typography variant="h5" className={classes.title}>{title}</Typography>
                <Typography variant="body1" >{text}</Typography>
            </Grid>
        </Grid>
    )
}

export default Feature

//Importiere Rezepte aus dem Web
/*
Über die Teilenfunktion kannst du Rezepte im Browser oder auch anderen Apps direkt in dein körbchen speichern.
                            <br />
                            Im körbchen kannst du deine Rezepte nachträglich nach belieben bearbeiten.

                            */
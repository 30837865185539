const sitesEn = [
    {
        //Blogs
        title: 'Food Blogs',
        sites: [
            {
                label: 'familystylefood.com',
                url: 'https://www.justonecookbook.com/'
            },
            {
                label: 'whiteonricecouple.com',
                url: 'https://whiteonricecouple.com/recipes/'
            },
            {
                label: 'jessicagavin.com',
                url: 'https://www.jessicagavin.com/'
            },
            {
                label: 'cookeatpaleo.com',
                url: 'https://cookeatpaleo.com/'
            },
            {
                label: 'loveandlemons.com',
                url: 'https://www.loveandlemons.com/'
            },
            {
                label: 'theendlessmeal.com',
                url: 'https://www.theendlessmeal.com/'
            },
            {
                label: 'sophiaskitchen.blog',
                url: 'https://sophiaskitchen.blog/recipes/'
            },
            {
                label: 'cooktoria.com',
                url: 'https://cooktoria.com/'
            }
        ]
    },
    {
        title: 'Vegetarian & Vegan',
        sites: [
            {
                label: 'elavegan.com',
                url: 'https://elavegan.com/'
            },
            {
                label: 'vanillaandbean.com',
                url: 'https://vanillaandbean.com/'
            },
            {
                label: 'cookieandkate.com',
                url: 'https://cookieandkate.com/'
            }
        ]
    },
    {
        title: 'Asian',
        sites: [
            {
                label: 'rasamalaysia.com',
                url: 'https://rasamalaysia.com/'
            },
            {
                label: 'mykoreankitchen.com',
                url: 'https://mykoreankitchen.com/'
            },
        ]
    },
    {
        title: 'Recipe Sites',
        sites: [
            {
                label: 'jamieoliver.com',
                url: 'https://www.jamieoliver.com/recipes/'
            },
            {
                label: 'kitchenstories.com',
                url: 'https://www.kitchenstories.com/en/recipes'
            },
            {
                label: 'bbcgoodfood.com',
                url: 'https://www.bbcgoodfood.com/recipes'
            },
            {
                label: 'justonecookbook.com',
                url: 'https://www.justonecookbook.com/'
            },
            {
                label: 'cooking.nytimes.com',
                url: 'https://cooking.nytimes.com/'
            },
            {
                label: 'food52.com',
                url: 'https://food52.com/'
            },
            {
                label: 'bosh.tv',
                url: 'https://www.bosh.tv/recipes'
            },
            {
                label: 'delish.com',
                url: 'https://www.delish.com/'
            }
        ]
    }
]

export default sitesEn
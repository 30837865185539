import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import 'lato-font';

const breakpoints = createBreakpoints({})

const palette: PaletteOptions = {
    primary: {
        main: '#00897B',
        contrastText: '#fff'
        /*main: '#AD974F',
        dark: '#8E793E',
        contrastText: '#FFF'*/
    },
    secondary: {
        main: '#fff',
        contrastText: '#00897B'
    },
    text: {
        secondary: '#FFF'
    },
    type: 'light',
    background: {
        default: '#FFF'
    }
};

const typography: TypographyOptions = {
    fontFamily: 'Lato',
    h4: {
        fontWeight: 'bolder',
        [breakpoints.down('sm')]: {
            fontSize: '1.8rem'
        }
    },
    h5: {
        fontWeight: 'bolder',
        textTransform: 'uppercase'
    },
    h6: {
        [breakpoints.down('sm')]: {
            fontSize: '1.0rem'
        }
    },
    subtitle1: {
        fontWeight: 'bold'
    }
}

export default createMuiTheme({
    typography,
    palette
});
const sitesDe = [
  {
    //foodblogs
    title: "Foodblogs",
    sites: [
      {
        label: "salamico",
        url: "https://www.salamico.de/rezepte",
      },
      {
        label: "gaumenfreundin.de",
        url: "https://www.gaumenfreundin.de/",
      },
      {
        label: "eat-this.org",
        url: "https://www.eat-this.org/",
      },
      {
        label: "emmikochteinfach.de",
        url: "https://emmikochteinfach.de/",
      },
      {
        label: "biancazapatka.com",
        url: "https://biancazapatka.com/de",
      },
      {
        label: "futterattacke.de",
        url: "https://www.futterattacke.de/",
      },
    ],
  },
  {
    //magazin
    title: "Magazin",
    sites: [
      {
        label: "springlane.de",
        url: "https://www.springlane.de/magazin/rezeptideen/",
      },
      {
        label: "flavourate.com",
        url: "https://flavourate.com/",
      },
    ],
  },
  {
    //vegan & vegetarisch
    title: "Vegan & Vegetarisch",
    sites: [
      {
        label: "veganheaven.de",
        url: "https://veganheaven.de/",
      },
      {
        label: "veganerezepte.eu",
        url: "https://veganerezepte.eu/",
      },
      {
        label: "rapunzel.de",
        url: "https://www.rapunzel.de/rezepte-bio-rapunzel.html",
      },
      {
        label: "elavegan.com",
        url: "https://elavegan.com/de",
      },
      {
        label: "veganvibes.de",
        url: "https://www.veganevibes.de/",
      },
    ],
  },
  {
    //Rezeptseiten
    title: "Rezeptseiten",
    sites: [
      {
        label: "kuechengoetter.de",
        url: "https://www.kuechengoetter.de/",
      },
      {
        label: "oetker.de",
        url: "https://www.oetker.de/rezeptsuche",
      },
      {
        label: "kitchenstories.com",
        url: "https://www.kitchenstories.com/de/rezepte",
      },
      {
        label: "rezeptwelt.de",
        url: "https://www.rezeptwelt.de/",
      },
      {
        label: "edeka.de",
        url: "https://www.edeka.de/rezepte/suche.jsp",
      },
      {
        label: "chefkoch.de",
        url: "https://www.chefkoch.de/",
      },
      {
        label: "kochbar.de",
        url: "https://www.kochbar.de/",
      },
      {
        label: "essen-und-trinken.de",
        url: "https://www.essen-und-trinken.de/",
      },
      {
        label: "eatsmarter.de",
        url: "https://eatsmarter.de/",
      },
      {
        label: "lecker.de",
        url: "https://www.lecker.de/",
      },
    ],
  },
];

export default sitesDe;
